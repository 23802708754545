import * as Msdyn365 from "@msdyn365-commerce/core";

export interface IDropshipState {
  name: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  isDropship: boolean;
  email: string;
}
export class GetDropshipAttributesInput implements Msdyn365.IActionInput {
  constructor() {}

  public getCacheKey = () => `getDropshipAttributes`;
  public getCacheObjectType = () => "getDropshipAttributes";
  public dataCacheType = (): Msdyn365.CacheType => "request";
}

const createInput = (
  args: Msdyn365.ICreateActionContext
): Msdyn365.IActionInput => {
  return new GetDropshipAttributesInput();
};

export async function DropshipAction(
  input: GetDropshipAttributesInput,
  ctx: Msdyn365.IActionContext
): Promise<IDropshipState> {
  return {
    name: "",
    street: "",
    postalCode: "",
    email: "",
    city: "",
    country: "",
    phone: "",
    isDropship: false,
  };
}

export default Msdyn365.createObservableDataAction({
  action: <Msdyn365.IAction<IDropshipState>>DropshipAction,
  id: "GetDropshipAttributes",
  input: createInput,
});
